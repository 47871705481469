import PropTypes from "prop-types";
import React from "react";
import { Link, withRouter } from "react-router-dom";
import Select from "react-select";
import { Button, Card, CardBody, CardTitle, Col, Input, Row } from "reactstrap";
import {
  OrderStatus,
  OrderStatusDetector,
  addresses as _addresses,
  replacementsCommodity,
} from "../../constants/constants";
import {
  capitalize,
  trimParagraph,
  replaceWords,
} from "../../utils/stringUtils";
import { isNumber } from "lodash";

const styles = {
  card: {
    marginBottom: 5,
  },
  cardLightGreen: {
    backgroundColor: "#e6ffe6",
  },
  cardLightYellow: {
    backgroundColor: "#FFFFDE",
  },
  itemButtonCol: {
    paddingTop: 10,
  },
  itemMessage: {
    clear: "both",
  },
  rowMarginBottom: {
    marginBottom: 10,
  },
  multipleLineMessage: {
    whiteSpace: "pre-wrap",
  },
};

class FbOrderItem extends React.Component {
  static propTypes = {
    item: PropTypes.shape({}).isRequired,
    member: PropTypes.shape({}).isRequired,
    dropDownToggleValue: PropTypes.number,
    changeFbOrderStatus: PropTypes.func,
    toggleCaller: PropTypes.func,
    toggleFeedback: PropTypes.func,
    toggleQuotation: PropTypes.func,
    toggleViewer: PropTypes.func,
    ios15: PropTypes.bool,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    const { addresses, commodity } = props.item;
    this.state = {
      addresses,
      addressFirstMoveable: addresses && addresses.length > 1,
      commodity: commodity ? commodity : "",
    };

    /**
     * Solution for iOS 15 window.getSelection issue
     */
    this.selectionTextSaved = null;
    this.selectionTextSavedTimer = null;
  }

  handleSelectChangeAddresses = (selectedOption) => {
    this.setState({
      addresses: selectedOption,
      addressFirstMoveable: selectedOption && selectedOption.length > 1,
    });
  };

  moveAddressFirst = (event) => {
    event.preventDefault();
    const { addresses } = this.state;
    if (addresses && addresses.length > 1) {
      const addressesTmp = [
        addresses[addresses.length - 1],
        ...addresses.slice(0, addresses.length - 1),
      ];
      addressesTmp.forEach((item, index) => {
        item.pos = index + 868686;
      });
      this.setState({
        addresses: [...addressesTmp],
      });
    }
  };

  cleanSelectionTextSaved = () => {
    if (window.getSelection().empty) {
      // Chrome
      window.getSelection().empty();
    } else if (window.getSelection().removeAllRanges) {
      // Firefox
      window.getSelection().removeAllRanges();
    }

    this.selectionTextSaved = null;
  };

  commodityConcatSelectedText = (withAnd = false, capitalizeNeed = false) => {
    let selectedText = capitalizeNeed
      ? capitalize(this.getSelectionText())
      : this.getSelectionText();

    if (selectedText) {
      const { commodity } = this.state;
      this.setState({
        commodity:
          (commodity || "") +
          (!commodity || commodity.endsWith(" ") ? "" : " ") +
          (commodity && withAnd ? "& " : "") +
          selectedText,
      });

      this.cleanSelectionTextSaved();
    }
  };

  commodityConcat_onClick = (event) => {
    this.commodityConcatSelectedText();
  };

  manyOrders_onClick = () => {
    const { commodity } = this.state;
    this.setState(
      {
        commodity: !commodity ? "Nhiều chuyến hàng: " : commodity,
      },
      () => {
        let selectedText = this.getSelectionText();
        if (selectedText) {
          if (!commodity || commodity.trim().endsWith(":")) {
            this.commodityConcatSelectedText(false, true);
          } else if (
            commodity &&
            window.confirm("& " + capitalize(selectedText))
          ) {
            this.commodityConcatSelectedText(true, true);
          }
        }
      }
    );
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  btnOK_onClick = (item) => {
    const { commodity } = this.state;
    let commodityText;
    const tmpSelectionText = this.getSelectionText();
    if (commodity && tmpSelectionText && commodity !== tmpSelectionText) {
      if (
        window.confirm(
          `NDTB SẼ LÀ: ${commodity}\nCHỨ KO PHẢI LÀ: ${tmpSelectionText}`
        )
      ) {
        commodityText = commodity;
      } else {
        commodityText = tmpSelectionText;
      }
    } else {
      commodityText = tmpSelectionText || commodity;
    }

    if (!commodityText && !window.confirm("NDTB SẼ ĐỂ TRỐNG KHI ĐĂNG ĐƠN?")) {
      return;
    }

    commodityText = capitalize(trimParagraph(commodityText));
    const commodityTextLowerCase = commodityText.toLocaleLowerCase();

    // Replace keywords with their respective replacementsCommodity
    let replaced = false;
    replacementsCommodity.forEach(({ keyword, replacement }) => {
      // Just only check string include first
      if (commodityTextLowerCase.includes(keyword)) {
        const commodityTextNew = replaceWords(
          commodityText,
          keyword,
          replacement
        );
        // If there is any regex replacements
        if (commodityText !== commodityTextNew) {
          commodityText = commodityTextNew;
          replaced = true;
        }
      }
    });

    if (replaced) {
      commodityText = window.prompt(
        "NDTB ĐC LỌC TỪ KHÓA SẼ LÀ:",
        commodityText
      );
      if (!commodityText) {
        return;
      }
    }

    this.setState(
      {
        commodity: commodityText,
      },
      () => {
        this.changeFbOrderStatusHandle(item._id, OrderStatus.ORDER_APPROVED);
      }
    );
  };

  changeFbOrderStatusHandle = (id, status) => {
    const { changeFbOrderStatus } = this.props;
    const { addresses, commodity } = this.state;

    changeFbOrderStatus(id, status, addresses, capitalize(commodity));
  };

  getSelectionText = (includeSavedValue = true) => {
    var text = "";
    if (window.getSelection) {
      text = window.getSelection().toString();
    } else if (document.selection && document.selection.type !== "Control") {
      text = document.selection.createRange().text;
    }

    /**
     * Solution for iOS 15 window.getSelection issue
     */
    if (!text && this.props.ios15 && includeSavedValue) {
      text = this.selectionTextSaved;
    }

    return text;
  };

  /**
   * Solution for iOS 15 window.getSelection issue
   * @param {*} e
   */
  saveSelectionText = () => {
    const selectedText = this.getSelectionText(false);
    if (selectedText) {
      this.selectionTextSaved = selectedText;
    } else {
      clearInterval(this.selectionTextSavedTimer);
      this.selectionTextSavedTimer = null;
    }
  };

  saveSelectionTextTrigger = (e) => {
    if (this.props.ios15) {
      this.selectionTextSavedTimer = setInterval(this.saveSelectionText, 250);
    }
  };

  getMessageSupportMultipleLine = (item, roleAdmin) => {
    let text;
    if (roleAdmin) {
      text = `[${item.keyword ?? ""}] [${item.phoneNumber}${
        item.phoneNumber2 ? "," + item.phoneNumber2 : ""
      }] ${item.message}`;
    } else {
      text = item.message;
    }

    return { __html: text };
  };

  handleKeyPressForSelect = (event) => {
    if (event.ctrlKey && event.altKey && event.key === "1") {
      this.moveAddressFirst(event);
    }
    if (event.ctrlKey && event.key === "Enter") {
      event.preventDefault();
      const { item } = this.props;
      this.btnOK_onClick(item);
    }
  };

  handleKeyPressForCommodity = (event) => {
    if (event.ctrlKey && event.key === "Enter") {
      event.preventDefault();
      const { item } = this.props;
      this.btnOK_onClick(item);
    }
  };

  render() {
    const {
      item,
      member,
      dropDownToggleValue,
      toggleCaller,
      toggleFeedback,
      toggleQuotation,
      toggleViewer,
    } = this.props;
    const { addresses, addressFirstMoveable, commodity } = this.state;

    let cardBackground;
    if (member.roleAdmin) {
      if (
        item.statusDetector === OrderStatusDetector.ORDER_SET_STATUS_BY_HUMAN
      ) {
        cardBackground = styles.cardLightGreen;
      } else if (
        item.statusDetector === OrderStatus.ORDER_APPROVED ||
        item.statusDetector === OrderStatus.ORDER_TRUCK_OWNER ||
        item.statusDetector === OrderStatus.ORDER_REJECTED
      ) {
        cardBackground = styles.cardLightYellow;
      }
    } else {
      cardBackground = null;
    }

    return (
      <Card key={`${item._id}`} style={styles.card}>
        <CardBody style={cardBackground}>
          <CardTitle>
            <Row>
              <Col lg="6">
                {!!item.postWall && (
                  <a
                    href={item.postWall}
                    className="small"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.postBy}
                  </a>
                )}
                {!item.postWall && item.postBy}
                <abbr className="small font-italic">
                  {" "}
                  - {item.postDateFromNow}
                </abbr>
              </Col>
              <Col lg="6" hidden={!isNumber(dropDownToggleValue)}>
                <Button
                  size="sm"
                  color="light"
                  onClick={this.moveAddressFirst}
                  style={{ float: "right", marginLeft: 1 }}
                  disabled={!addressFirstMoveable}
                >
                  🥇
                </Button>
                <Button
                  size="sm"
                  color="light"
                  onClick={this.commodityConcat_onClick}
                  style={{ float: "right", marginLeft: 1 }}
                >
                  📌
                </Button>
                <Button
                  size="sm"
                  color="light"
                  onClick={this.manyOrders_onClick}
                  style={{ float: "right", marginLeft: 1 }}
                >
                  🤹‍♂️
                </Button>
                <Button
                  size="sm"
                  color="light"
                  onClick={() => toggleViewer(item)}
                  style={{ float: "right", marginLeft: 1 }}
                >
                  {item.viewers ? item.viewers.length : 0} 👀
                </Button>
                <Button
                  size="sm"
                  color="light"
                  onClick={() => toggleCaller(item)}
                  style={{ float: "right", marginLeft: 1 }}
                >
                  {item.callers ? item.callers.length : 0} 🤙
                </Button>
                <Button
                  size="sm"
                  color="light"
                  onClick={() => toggleQuotation(item)}
                  style={{ float: "right", marginLeft: 1 }}
                >
                  {item.quotations ? item.quotations.length : 0} 💸
                </Button>
                <Button
                  size="sm"
                  color="light"
                  onClick={() => toggleFeedback(item)}
                  style={{ float: "right", marginLeft: 1 }}
                >
                  {item.feedbacks ? item.feedbacks.length : 0} 💥
                </Button>
              </Col>
            </Row>
          </CardTitle>
          <Row style={styles.rowMarginBottom}>
            <Col lg={8} md={8} sm={12} xs={12}>
              <Select
                placeholder="Tỉnh"
                value={addresses}
                onChange={(selectedOption) =>
                  this.handleSelectChangeAddresses(selectedOption)
                }
                options={_addresses}
                isMulti
                getOptionValue={(option) => option.key}
                getOptionLabel={(option) => option.name}
                onKeyDown={this.handleKeyPressForSelect}
              />
            </Col>
            <Col lg={4} md={4} sm={12} xs={12}>
              <Input
                type="textarea"
                rows={1}
                name="commodity"
                placeholder="NDTB (Hàng hóa)"
                value={commodity}
                onChange={this.handleChange}
                onKeyDown={this.handleKeyPressForCommodity}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <div
                onTouchEnd={this.saveSelectionTextTrigger}
                style={styles.multipleLineMessage}
                dangerouslySetInnerHTML={this.getMessageSupportMultipleLine(
                  item,
                  member.roleAdmin
                )}
              />
            </Col>
          </Row>
          <Row hidden={!isNumber(dropDownToggleValue)}>
            <Col xs="4" style={styles.itemButtonCol}>
              <Button
                className={`${
                  dropDownToggleValue !== OrderStatus.ORDER_TRUCK_OWNER
                    ? "btn btn-warning"
                    : "d-none"
                } btn-block`}
                onClick={() => {
                  this.changeFbOrderStatusHandle(
                    item._id,
                    OrderStatus.ORDER_TRUCK_OWNER
                  );
                }}
              >
                <i className="icon-like" /> Xe
              </Button>
            </Col>
            <Col xs="4" style={styles.itemButtonCol}>
              <Button
                className="btn btn-success btn-block"
                onClick={() => {
                  window.open(
                    `/fborder-others/${item._id}/${encodeURIComponent(
                      item.postWall
                    )}/${item.phoneNumber}${
                      item.phoneNumber2 ? "," + item.phoneNumber2 : ""
                    }`,
                    "_blank"
                  );
                }}
              >
                <i className="icon-magnifier" /> Khác
              </Button>
            </Col>
            <Col xs="4" style={styles.itemButtonCol}>
              <a
                className={`${
                  item.postLink ? "btn btn-success" : "d-none"
                } btn-block`}
                href={item.postLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="icon-diamond" /> Gốc
              </a>
            </Col>
            <Col xs="4" style={styles.itemButtonCol}>
              <Link
                className="btn btn-success btn-block"
                to={`/fborder/${item._id}`}
              >
                <i className="icon-pencil" /> Sửa
              </Link>
            </Col>
            <Col xs="4" style={styles.itemButtonCol}>
              <Button className={`btn btn-info btn-block`} onClick={() => {}}>
                <i className="icon-puzzle" /> AI
              </Button>
            </Col>
            <Col xs="4" style={styles.itemButtonCol}>
              <Button
                className="btn btn-success btn-block"
                onClick={() => {
                  const selectedText = this.getSelectionText();
                  if (selectedText) {
                    window.open(
                      `https://www.google.com/search?q=${selectedText} thuộc tỉnh nào`,
                      "_blank"
                    );
                  }
                }}
              >
                <i className="icon-magnifier" /> Tỉnh?
              </Button>
            </Col>
            <Col xs="4" style={styles.itemButtonCol}>
              <Button
                className={`${
                  dropDownToggleValue !== OrderStatus.ORDER_REJECTED
                    ? "btn btn-danger"
                    : "d-none"
                } btn-block`}
                onClick={() => {
                  this.changeFbOrderStatusHandle(
                    item._id,
                    OrderStatus.ORDER_REJECTED
                  );
                }}
              >
                <i className="icon-dislike" /> Huỷ
              </Button>
            </Col>
            <Col xs="4" style={styles.itemButtonCol}>
              <Button
                className={`${
                  dropDownToggleValue !== OrderStatus.ORDER_IGNORED
                    ? "btn btn-warning"
                    : "d-none"
                } btn-block`}
                onClick={() => {
                  this.changeFbOrderStatusHandle(
                    item._id,
                    OrderStatus.ORDER_IGNORED
                  );
                }}
              >
                <i className="icon-clock" /> Hoãn
              </Button>
            </Col>
            <Col xs="4" style={styles.itemButtonCol}>
              <Button
                className={`${
                  dropDownToggleValue !== OrderStatus.ORDER_APPROVED
                    ? "btn btn-success"
                    : "btn btn-secondary"
                } btn-block`}
                onClick={() => this.btnOK_onClick(item)}
              >
                <i className="icon-like" />{" "}
                {dropDownToggleValue !== OrderStatus.ORDER_APPROVED
                  ? "Hàng"
                  : "Lưu"}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ textAlign: "center" }}
            >
              <abbr className="small font-italic">{item.groupName}</abbr>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

export default withRouter(FbOrderItem);
