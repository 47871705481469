import moment from 'moment';
import _ from 'lodash';
import Store from '../store/fborders';
import { translateFromNow } from '../utils/dateUtils';

export const initialState = Store;

const marshalData = (original) => {
  original.forEach((element) => {
    element.postDateFromNow = translateFromNow(element.postDate);
    element.postDateString = moment(element.postDate).format('DD/MM HH:mm');
    element.startDateString = moment(element.startDate).format('DD/MM/YYYY');
    element.endDateString = moment(element.endDate).format('DD/MM/YYYY');

    element.addresses = _.sortBy(element.addresses, ['pos']);
  });

  return original;
};

export default function fborderReducer(state = initialState, action) {
  switch (action.type) {
    case 'FBORDERS_LOADING': {
      return {
        ...state,
        loading: action.data,
      };
    }
    case 'FBORDERS_ERROR': {
      return {
        ...state,
        error: action.data,
      };
    }
    case 'FBORDERS_REPLACE': {
      const fborders = marshalData(action.data);

      return {
        ...state,
        error: null,
        loading: false,
        fborders,
        page: action.page,
        perPage: action.perPage,
      };
    }
    case 'FBORDERS_REMOVE': {
      for (let index = 0; index < state.fborders.length; index += 1) {
        const element = state.fborders[index];
        if (element._id === action.data) {
          return {
            ...state,
            fborders: [
              ...state.fborders.slice(0, index),
              ...state.fborders.slice(index + 1),
            ],
          };
        }
      }
      break;
    }
    case 'FBORDERS_UPDATE': {
      const fborders = state.fborders.map((element) => {
        if (element._id !== action.data._id) {
          // This isn't the item we care about - keep it as-is
          return element;
        }

        // Otherwise, this is the one we want - return an updated value
        return {
          ...element,
          ...action.data,
        };
      });

      return {
        ...state,
        fborders,
      };
    }
    case 'FBORDERS_ADD': {
      const fborders = state.fborders.slice();
      fborders.splice(0, 0, action.data);

      return {
        ...state,
        fborders,
      };
    }
    case 'FBORDERS_PROPERTY_REPLACE': {
      if (action.name && action.data) {
        return {
          ...state,
          [action.name]: action.data,
        };
      }
      break;
    }
    default:
      return state;
  }

  return state;
}
